import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { IPartner } from '../hooks/usePartnersQuery'

const Container = styled.div`
  background-color: white;
  padding: 17px 17px 22px 17px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 45px 17px 34px 17px;
  }
`

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(104px, 1fr));
  max-width: 682px;
  margin: 0 auto;
  gap: 7px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 14px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
`

const Item = styled.li`
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`

const PartenaireLink = styled.a`
  display: inline-flex;
  padding: 7px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 16px;
  }
`

interface PartenaireProps {
  item: IPartner
}

const Partenaire: React.FC<PartenaireProps> = ({ item }) => {
  const image = getImage(
    item.cover?.imageFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <Item>
      <PartenaireLink href={item.url} target="_blank">
        {image && <GatsbyImage image={image} alt={item.cover?.name ?? ''} />}
      </PartenaireLink>
    </Item>
  )
}

interface PartenairesGridProps {
  items: IPartner[]
}

const PartenairesGrid: React.FC<PartenairesGridProps> = ({ items }) => (
  <Container>
    <List>
      {items.map((item, index) => (
        <Partenaire key={index} item={item} />
      ))}
    </List>
  </Container>
)

export default PartenairesGrid
