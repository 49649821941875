import { useStaticQuery, graphql } from 'gatsby'
import { PartnersQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'

export type IPartner = NonNullable<
  Unpacked<NonNullable<PartnersQuery['strapi']['partners']>>
>

const useFaqQuery = (): IPartner[] => {
  const { strapi }: PartnersQuery = useStaticQuery(
    graphql`
      query Partners {
        strapi {
          partners(sort: "order:asc") {
            cover {
              url
              name
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    quality: 100
                    width: 130
                  )
                }
              }
            }
            name
            id
            url
          }
        }
      }
    `
  )
  return strapi.partners?.filter(notEmpty) ?? []
}

export default useFaqQuery
